import React from 'react';

import Contact from '../modules/pages/contact/Contact';

const contact = () => {
  return (
    <Contact
      seo={{
        title: 'Contate-nos',
        description: 'Nunca foi tão fácil entender seu cliente! Integre hoje e crie suas análises em minutos.',
        lang: 'pt',
      }}
    />
  );
};

export default contact;
